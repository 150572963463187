import { createGlobalStyle } from "styled-components";

export const theme = {
  colors: {
    first: "#white",
    second: "#cacaca",
    third: "rgb(202, 202, 202)",
    button: "#b0b0b0",
    button_green: "#00ff00",
    button_red: "#ba2121",
    button_red_hover: "#a41515",
    text: "black",
  },
};

const GlobalStyle = createGlobalStyle`
  * {
    user-select: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;
    overflow: hidden;
    background-color: white;
    scrollbar-width: thin;
    scrollbar-color: #79aec8 white;
  };

  overflow-x: hidden;

  /* Modern scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #79aec8;
    border-radius: 20px;
    border: 3px solid #f5f5f5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

`;

export default GlobalStyle;
