import React, { CSSProperties, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";

const Wrapper = styled.div`
  background-color: inherit;

  h1 {
    background-color: inherit;
  }
`;

function Spinner({ short = false }: { short?: boolean }) {
  const [override, setOveride] = useState<CSSProperties>({
    backgroundColor: "inherit",
  });

  const { error } = useAuth() as authContextProps;
  return (
    <Wrapper style={{ height: short ? "fit-content" : "100vh" }}>
      <BeatLoader cssOverride={override} color={"rgb(65, 118, 144)"} />
      <div>{error.message}</div>
    </Wrapper>
  );
}

export default Spinner;
