import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";

import Spinner from "../components/Spinner";

import {
  sendPasswordRecoveryMail,
  resetPassword,
  confirmTokenRecovery,
} from "../networking/mailControllerNetwork";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;

  .title {
    font-weight: 100;
    margin: 1em 0;
  }

  .box {
    margin-top: 3em;
    border: 1px solid #ccc;
    display: flex;
    width: fit-content;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    max-height: fit-content;
  }
  .link {
    color: inherit;
    background-color: inherit;
  }

  .error {
    color: red;
    width: 100%;
  }

  .link:hover {
    text-decoration: none;
  }
  .footer {
    margin-top: auto;
    font-weight: 100;
    background-color: inherit;
    margin-bottom: 2em;
  }
`;

function ResetPasswordPage() {
  const [error, setError] = useState("");
  const [stage, setStage] = useState("send-token-to-mail");
  const [isLoading, setIsLoading] = useState(false);

  const [tokenRecovery, setTokenRecovery] = useState("");

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const onSubmitEmail = () => {
    setError("");
    setIsLoading(true);
    sendPasswordRecoveryMail(email)
      .then((res) => {
        setStage("token-sent");
      })
      .catch((e: any) => {
        console.log(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitToken = () => {
    setError("");
    setIsLoading(true);
    setTokenRecovery(token);
    confirmTokenRecovery(token)
      .then((res) => {
        if (res.status === "OK") {
          setStage("set-passwords");
        } else {
          // throw error
          throw new Error("Invalid token");
        }
      })
      .catch((e: any) => {
        console.log(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitPasswords = () => {
    setError("");
    if (newPassword1 === newPassword2) {
      if (newPassword1.length < 8) {
        setError("Password must be at least 8 characters long");
        return;
      }
      setIsLoading(true);
      resetPassword(tokenRecovery, newPassword1)
        .then((res) => {
          if (res.status === "OK") {
            setStage("done");
          } else {
            throw new Error("Invalid token");
          }
        })
        .catch((e: any) => {
          console.log(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError("Passwords do not match");
    }
  };
  return (
    <Wrapper>
      <div className="box">
        {stage === "send-token-to-mail" && (
          <>
            <div className="title">
              Enter email address to reset your password
            </div>
            <Input
              parentFunctionOnEnter={onSubmitEmail}
              type={"email"}
              label="Email"
              parentFunction={(value: string) => {
                setEmail(value);
              }}
            />
            <Button parentFunction={onSubmitEmail}>Send</Button>

            {isLoading && <Spinner short={true} />}
          </>
        )}
        {stage === "token-sent" && (
          <>
            <div className="title">
              Copy token sent on your email address here
            </div>
            <Input
              parentFunctionOnEnter={onSubmitToken}
              type="text"
              label="Token"
              parentFunction={(value: string) => {
                setToken(value);
              }}
            />
            <Button parentFunction={onSubmitToken}>Send</Button>
            {isLoading && <Spinner short={true} />}
          </>
        )}
        {stage === "set-passwords" && (
          <>
            <div className="title">Set new password</div>
            <Input
              parentFunctionOnEnter={onSubmitPasswords}
              type="password"
              label="New Password"
              parentFunction={(value: string) => {
                setNewPassword1(value);
              }}
            />
            <Input
              parentFunctionOnEnter={onSubmitPasswords}
              type="password"
              label="Repeat New Password"
              parentFunction={(value: string) => {
                setNewPassword2(value);
              }}
            />
            <Button parentFunction={onSubmitPasswords}>Send</Button>
            {isLoading && <Spinner short={true} />}
          </>
        )}
        {stage === "done" && (
          <div>
            Password changed successfully. Proceed to{" "}
            <span>
              <Link to="/login">login</Link>
            </span>
          </div>
        )}
        {error && <div className="error">{error}</div>}
      </div>
      <div className="footer">
        Didn't forget password?{" "}
        <Link className="link" to="/login">
          Login
        </Link>
      </div>
    </Wrapper>
  );
}

export default ResetPasswordPage;
