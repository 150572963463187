import styled from "styled-components";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";

import { authContextProps } from "../@interfaces/authContext";

const NavBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  background-color: #417690;
  gap: 1em;

  .link {
    background-color: inherit;
    font-size: 1.2rem;
    color: white;
  }

  .link--home {
    text-decoration: none;
  }

  .link:hover {
    text-decoration: none;
  }

  /* .push-right {
    margin-right: auto;
  } */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  .nav__footer {
    margin-top: auto;
    background-color: #417690;
    min-height: 35px;
  }

  .back__button {
    &:hover {
      cursor: pointer;
    }
    width: 25px;
    color: white;
    background-color: #417690;
    font-size: 0.5rem;
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

function Navbar() {
  const { token, logOut } = useAuth() as authContextProps;
  const navigate = useNavigate();

  return (
    <Wrapper>
      <NavBar>
        {!token && (
          <>
            <Link className="link link--home push-right" to={"/"}>
              Stribor
            </Link>
          </>
        )}
        {token && (
          <>
            {window.location.pathname !== "/" && (
              <img
                onClick={() => navigate("/")}
                className="back__button"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADw0lEQVR4nO2aSU8UURDH24CCwaigoPGkRwNRv4Q7iHoT9SaBi0LUK+BZPZmY+Dk0aDQR4w6Ke1wQPbkcDHoTNWB+pvD/wjP09HT39CwY/skkk6nXVfW66r3aJggW8J8CaAD2AmeAQeAN8A34pY99fy2arWkH6oNKAFALHAauAb9JjmngKnAIqCnHBpYCJ4DPnlI/gSGgT5bZaG8cWKxPvX4zWj9wQ884fAKO28sp1SZ2A+89BR4CR4AVKXitBDqBUY/fO2Bnsd3ogifwEbAtQ/47gCce//OZWwdYK8UN34GjQFWmQv7KqQJ6gEnP2muyYr5B5ka3TksmjKNlbgLGJHPcdCiUYaPH8AGwOjNt88uuB+5Itp3JtYWcCedO94C6zLXNr0MdMOy5WfIz4x1sc6eGomgaT49VnlecT3PFuoNd9DMR88xMSqcdSYKdixNHgwoB0Osd/vwuBpz04kTmV2xaANXAU+nWm29xjVIFw9agwgDskm6fI62i5G3mhiggcFq6cb8QhXMBWOTdpB1RCy2LNRzJuSh6E6/0/GMTmpRHHABdknElqp6YVkaaKAG0NMLbxOvUwSt+oLT6ZipUT2CfFLkeh6H3XBPwQs9aMbUuKDKAm5LXFkY8K2JfQku8LIUlfACnJPN0GNFKUMOeIH4eVlJLhHjPxTDiWxE3ziFGu1PJLOEANLsXGEb8KmJDDEs8L4clHCwLl/wvYUS7CQxL5hD/vcfdJoqJ27l08AK34WchG3lWgo3cKmQjaVxrrEyu1RjlWv/NYR8UsT3F9VtSywD7o65fFxD753tA3CviUEKmTaW2DLMpSmuuZMwljSsrOGls8JLG5bkWWUPZ0FlgGv80C6XDAHRLxuWoRQe1aDRIAW3GytHhNM/ng+KY1TqGA/kCzUct3B5UGIBW6fYh7xhCrX2080prPjyTbsfidhhdr7cnqBAw+4LHYg+FbD6hh6wptqnoWubXZwvwQzolG2VoPuHewKqiaRkvgxiXLufSMKhV4xg1ksvRxF4GjEiHkdRzRr0NK57cWKExc22jA99dyX5X8MBHg55xz802Z6Zt9JkYl0zLytdnxXiN52aTaihXZ8J87hV73DvY5k5NWQup9S6AmTQkq+mrIraNMvzq81xRZ++avjqzo15sV5p/MegcdHtph3OlzKbFcazT66UzKCO19HpAfadmTZuW6GPfW1QUDWit6xO4tONYuf4BYblZhzWUVQIkxZQq0wNl2UAYrKFsvVir2oBLqksmvD/VTCjNv6g1bTnriQUE8x9/AHfGKb0PMWUfAAAAAElFTkSuQmCC"
              />
            )}

            <Link className="link push-right" to={"/"}>
              Home
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://kamis-1.fesb.hr/admin/"
              className="link"
            >
              Admin Page
            </a>
            {/* <Link className="link " to={`/controls`}>
              Controls
            </Link> */}
            {/* <Link className="link " to={`/user/${user.username}`}>
              {user.username}
            </Link> */}
            <Link onClick={() => logOut()} className="link" to="/login">
              Log Out
            </Link>
          </>
        )}
      </NavBar>
      <Outlet />
      {/* <footer className="nav__footer"></footer> */}
    </Wrapper>
  );
}

export default Navbar;
