import { Link } from "react-router-dom";
import styled from "styled-components";
import { Mark, Selection } from "../@interfaces/interfaces";
import { useParams } from "react-router-dom";
import { useRef, useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin-top: 1em;

  .no-shrink {
    flex-shrink: 0;
  }

  .title--box {
    background-color: #417690;
    color: #f5dd5d;
    padding: 0.5em;
    margin-bottom: 0em;
  }
`;

const ScrollContent = styled.div`
  display: grid;
  align-items: center;
  box-sizing: border-box;
  grid-template-rows: repeat(auto-fill);
  overflow: scroll;
  width: 100%;

  .mark {
    height: 20px;
    display: flex;
    gap: 0.5em;
  }

  .mark--disabled {
    font-style: italic;
    background-color: gray;
    color: white;
    border-radius: 4px;
    font-size: 0.8rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    align-self: center;
  }

  .mark--empty {
    background-color: #f5dd5d;
    color: white;
    border-radius: 4px;
    font-size: 0.8rem;
    width: 65px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    align-self: center;
  }

  .mark--marked {

    background-color: #609ab6;
    color: white;
    border-radius: 4px;
    width: 65px;
    font-size: 0.8rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    align-self: center;
  }

  .mark--current {
    a {
      background-color: #609ab6;
      color: white;
      border-radius: 4px;
    }
  }
  .link {
    padding: 2px 4px;
  }

  .link--reset {
    color: inherit;
    text-decoration: none;
  }
  .length {
    font-size: 0.8em;
    font-weight: 200;
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
  }
    .eye--icon {
      width: 20px;
      opacity: 1
    }
`;

interface SequenceNavProps {
  markList: Mark[];
  selections: Selection[];
}

function SequenceNav({ markList, selections }: SequenceNavProps) {
  const { frame } = useParams<{ frame: string }>();
  const { sequence_name } = useParams<{ sequence_name: string }>();

  const scrollRef = useRef<HTMLDivElement>(null);

  const sortedMarkList = markList.sort((a, b) => {
    const aNumber = parseInt(a.name.split("-frame")[1], 10);
    const bNumber = parseInt(b.name.split("-frame")[1], 10);
    return aNumber - bNumber;
  });

  useEffect(() => {
    // scroll to current frame
    const currentFrame = document.querySelector(".mark--current");
    if (currentFrame && scrollRef.current) {
      currentFrame.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Wrapper>
      <div className="no-shrink title--box">{sequence_name}</div>
      <ScrollContent ref={scrollRef} id="sequence_nav">
        {sortedMarkList.map((mark, i) => {
          return (
            <div
              className={`mark ${mark.marked ? "" : ""} ${
                frame === mark.name ? "mark--current" : ""
              }`}
              key={i}
            >
              <Link
                className="link link--reset"
                to={`/${sequence_name}/${mark.name}`}
              >
                {mark.name.replace("image", "sequence").split("-")[0]}
              </Link>
              {!mark.marked && <span className="mark--disabled"><img className="eye--icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVR4nO2Ze2jOYRTHP2zjdZ+ZFVZDLWm5pSXxBykpk2RYCRGSlOIvSyGFZMU/UhKi/KUkqZH9YYjl0jbN1ELkLom5zXh16vurp9V7+b37vRf6fepp7949zznPec5zOecMQkJCQkJCcoMIMBfYA5wHWoEPwHegG/gIPAHqgcPAUqCYHGIOcAr4DER9NjPwCrAa6J8tAxYBTc6k/gD3gDpgOTAVKJKn8oDhQDmwENgJXJW3vPEvge2ZNKhc28ObwBtgFzA+BVmFwHrggSOvQ8amlQ1ApxTa/t+iFQ+CBUCLY9AxYCABUwCccJScBkYErQTIB7Y5W+4+MCZIBZck2LxRQ/qZBDyWzhfAyCCE2kF9C7wCppM5ioBrwA+dy0CwQzmYzNMHGJQFvSEh/zr5iTrYNfcM2E/usgLoUtAZk1J1+g3MJPco0lNg70t1os516tiiFz2XOK653dLVHJdhek2jCgZzhfnaKV2KrJNingZZqyL7jAXea3F3+B28VwM/ARPJHkOBZs2lXiGTL/oCFyTgNVBB5ikEbmsO7fo9JQYADRL0DphG5ihWxmm6nwJlvRVY4+QhX4F1pJ9ZwHNH74wgCgtekuOtjrUzaaqA9ANqgV/S81M/rSJTkqrQSqcyclTfVausY999AXYHmOpaMaNDsu22PKLs0Et/HwGj/AqtUE5uAs7q4HtYkeGy4x1LuA4AE1KYfIkqJ22OvIfA7B59WlPxzDiVZmzgxTivuxXj7vSoUzXLe6uUTZYpIYtodScDi4GDQKOzdaKK8dbEuF59GzPacW9DEttmq+MVz4N+2jdVH+3zyQS6fBnjHWhb6SEJBEccz1Vp+1XqsNp2vOH83XuLmlRO3afoIaKt2i3vlPowxmTFpFEdkin1bHZus3gBnGdIPM6pz6Ek9JoxdxU49poC7WlTviRB32QMmaKSa2ea6mUx2ejcMO6NlqohOGXYWjJEgXNArVhNQIbYmYkqcbLQKO2slcK2JLzhxxDDCxA3kQHapWxlkv39GLLMiXTTzk21vDQYkqer3/7NkHP4MYRkcvFsYW/T9WxPIiQkhP+Dv586CF9EuOZSAAAAAElFTkSuQmCC"/></span>}
              {mark.marked && <span className="mark--disabled"><img className="eye--icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmklEQVR4nO2YSYxNQRSGvzYudBvb1KbEvEBiZWXaGiLmGBohkRgiEnZig0aMscNeYg4xkzazQGJKCMGCBdIkpkSj25Mj/00q1637rvc0Lak/qfTtOqdO1ak6569TDwICAgICAgICAv4HtAbGAxuAo8BDoAaoVatR3xHpjAPKaCQoA+YB1cA3IPebzcacA+b+K6d6AduA97GF3QM2AxOBQUA7oLmafQ8GJgHrgStAnTPWbG0BevwNBzoCO4AvMQfWABUF2CuXU66tWmC7ZA2COcBbTfbdCaUDRdotAY7JVp1s2/cboJI/HEannR27CozVqXyVPAkTgIvAJ7ULIoMk9NXGfJPtS858J4GexToxHfjg7NBc7eBC9e3xjNuYkuBVnjH7JF+s/yvFdDmtYVohDjRTMkeTnwU6O/IT6p/hOYl8bGXUG8dsyU45fZ2caPguIrG1ZUJbhUEUs6uBJjGdF5L3SRh/MYMj5xPG9ZPseazf5l7lMFy11piKrqLQnI51tEevVjqtEmQfMzhioRJHqWSfPXOOckLtDtDF54Ql1DMp2t/+KQ5HeVP2Bx0xWz5ZhP7OGp8m3Tnm3WMp3E7zVngk3SRno7BMaxYecQyQzMqYfFFzR7qP4mu9LsFdoAP5cTIl2cdncMQoNo5Zkh3PMH+51mr611zBNXXej7GTD0ukv9cjr0pxYq1nzH7JF2WYv7PW+osjbmg90PGlobsuL7sUe3t0xomdPqpVe04iuhDNltnslmfuCoVfYmhFyf7UUfAtMMJu6R6keByWrZ159Po4G/4krcDs6sSeUd3wPDsTsdeKwn1gpWy8y0MyI1VdZCWkn5dNtQbYcS9TWYInset1Wa0o0Ik62fCFnc293Km47f3SJusEzfQ2yDlMYiV8EpZqIaZ3yHPbJ+VEFE71Io8kdHLKIStRNgFNKbJofKlSvsST2NEj64sYaCYwUDd2qb5nqez/6oRT0kmUqEB95VySUykSFXpnR6dzAxjm4fYdGZ+8prPLw45D9VTIOUWk76lQECqdZKvXDwwjEvS66S44ISqP6PeBQnSRh2KtljrmhGmNKuIGQbnK+6hotHYTmA+0L8CeVRELgFuOvc/A1owVRtHoITJwf3ywuL8MrAMmA0O0mBZAS31b3xTd/FdiYWj5sinDhdggKFNSnnUS+HeajTkjEjFCaBQoFQNViVotJ147P9C9Vt9h6YxpTIsPCAgICAgICAjAix96n4B9AZJO9gAAAABJRU5ErkJggg=="/></span>}
              {mark.marked  && (
                <span className="mark--marked">{mark.length - mark.empty_count} done</span>
              )}
              {mark.empty_count > 0 && (
                <span className="mark--empty">{mark.empty_count} empty</span>
              )}

              <div className="length">{mark.length} frames</div>
            </div>
          );
        })}
      </ScrollContent>
    </Wrapper>
  );
}

export default SequenceNav;
