import styled from "styled-components";
import { useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useAuth } from "../context/auth";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;

  .box {
    margin-top: 3em;
    border: 1px solid #ccc;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
  }

  .error {
    color: red;
  }

  .link {
    color: inherit;
    background-color: inherit;
  }

  .link:hover {
    text-decoration: none;
  }

  .footer {
    margin-top: auto;
    font-weight: 100;
    background-color: inherit;
    margin-bottom: 2em;
  }
`;

function LoginPage() {
  const { login, error, setError }: any = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onChangeUsername = (value: string) => {
    setUsername(value);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  useEffect(() => {
    //check if window is less than 400px
    if (window.innerWidth < 700) {
      alert("To use this web tool please use a device with a larger screen.");
    }
  }, []);

  const onSubmit = () => {
    setError("");
    login(username, password);
  };

  return (
    <Wrapper>
      <div className="box">
        <Input
          parentFunction={onChangeUsername}
          parentFunctionOnEnter={onSubmit}
          label={"Username:"}
          type={"name"}
        />
        <Input
          parentFunction={onChangePassword}
          parentFunctionOnEnter={onSubmit}
          label={"Password:"}
          type={"password"}
        />
        <Button parentFunction={onSubmit}>Log in</Button>
        {error && <div className="error">{error.message}</div>}
      </div>
      <div className="footer">
        Can't login?{" "}
        <Link className="link" to="/reset-password">
          Reset password
        </Link>
      </div>
    </Wrapper>
  );
}

export default LoginPage;
