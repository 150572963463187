import React, { useEffect } from "react";
import Button from "./Button";
import styled from "styled-components";
import { Mark } from "../@interfaces/interfaces";
import {
  createMaskOfEntireSequence,
  submitSequenceForReview,
  returnSequence,
} from "../networking/sequenceControllerNetwork";
import { sendRejectMail } from "../networking/mailControllerNetwork";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import NoSmokeRadio from "./NoSmokeRadio";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0.5em 0;
  flex-shrink: 0;
  width: 100%;
  margin-top: auto;

  .radio__box {
    display: flex;
    align-items: center;
    gap: 3px;
    margin: 0.5em 0;
  }

  .button__tooltip {
    font-weight: 200;
  }

  .button__box {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
  }
`;

interface ControlPanelProps {
  folder_type?: string;
  markList: Mark[];
  triggerFetch: () => void;
}

function ControlPanel({
  folder_type = "oznacene",
  markList,
  triggerFetch,
}: ControlPanelProps) {
  const navigate = useNavigate();
  const { sequence_name } = useParams<{
    sequence_name: string;
  }>();
  const { token } = useAuth() as authContextProps;
  const [loading, setLoading] = React.useState(false);
  const [isEverythingMarked, setIsEverythingMarked] = React.useState(false);

  const handleAccept = () => {
    if (sequence_name) {
      setLoading(true);
      createMaskOfEntireSequence(token, sequence_name).then((res) => {
        if (res) {
          setLoading(false);
          navigate("/");
        }
      });
    }
  };

  const handleReject = () => {
    let message = "";
    message += prompt(
      "Reject with message. \nMail will be sent to user who submited this sequence"
    );

    if (sequence_name && message.length > 0) {
      setLoading(true);
      sendRejectMail(token, message, sequence_name);
      returnSequence(token, sequence_name).then((res) => {
        if (res) {
          setLoading(false);
          navigate("/");
          window.location.reload();
        }
      });
    } else {
      window.alert(
        "Mail not sent. You must enter a rejection message to reject the sequence"
      );
    }
  };

  const handleSubmit = () => {
    if (sequence_name) {
      setLoading(true);
      submitSequenceForReview(token, sequence_name).then((res) => {
        if (res) {
          setLoading(false);
          navigate("/");
        }
      });
    }
  };

  useEffect(() => {
    if (markList.length > 0) {
      const unmarked = markList.filter((mark) => !mark.marked);
      if (unmarked.length === 0) {
        setIsEverythingMarked(true);
      }
    }
  }, [markList]);

  return (
    <Wrapper>
      {folder_type === "oznacene" && (
        <div className="button__box">
          <Button parentFunction={handleAccept}>Accept and create masks</Button>

          <Button parentFunction={handleReject} red_color={true}>
            Reject sequence
          </Button>
        </div>
      )}
      {folder_type === "neoznacene" && (
        <>
          <NoSmokeRadio triggerFetch={triggerFetch} />
          <div className="button__tooltip">
            {isEverythingMarked
              ? "All sequences are checked. You can submit"
              : "You can submit once you have checked all sequences"}
          </div>

          <div
            className={`button__box 
            
            `}
          >
            <Button
              disabled={!isEverythingMarked}
              parentFunction={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      {loading && <Spinner />}
    </Wrapper>
  );
}

export default ControlPanel;
