export const url = process.env.REACT_APP_API_URL;

const fetchAPI = async (
  endpoint: string,
  method: "GET" | "POST",
  token?: string,
  body?: any
) => {
  try {
    let options: RequestInit = {};
    if (token) {
      options = {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      };
    } else {
      options = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${url}${endpoint}`, options);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const data = await response.json();
      throw new Error(data.email);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

export const sendRejectMail = async (
  token: string,
  message: string,
  sequence_name: string
) => fetchAPI(`mail/`, "POST", token, { message, sequence_name });

export const sendPasswordRecoveryMail = async (email: string) =>
  fetchAPI(`password_reset/`, "POST", "", { email });

export const confirmTokenRecovery = async (recoveryToken: string) =>
  fetchAPI(`password_reset/validate_token/`, "POST", "", {
    token: recoveryToken,
  });

export const resetPassword = async (recoveryToken: string, password: string) =>
  fetchAPI(`password_reset/confirm/`, "POST", "", {
    token: recoveryToken,
    password,
  });
