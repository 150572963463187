import React, { useState } from "react";
import styled from "styled-components";
import {
  save_nosmoke,
  undo_save_nosmoke,
} from "../networking/sequenceControllerNetwork";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import { ContextProps } from "../@interfaces/interfaces";
import { Context } from "../context/context";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0.5em 0;
  .text {
    font-size: 0.8em;
    font-weight: 100;
    width: 110px;
  }
`;

interface NoSmokeRadioProps {
  triggerFetch: () => void;
}

function NoSmokeRadio({ triggerFetch }: NoSmokeRadioProps) {
  const [isChecked, setIsChecked] = useState(false);
  const { clearSelections } = React.useContext(Context) as ContextProps;

  const { token } = useAuth() as authContextProps;
  const { sequence_name } = useParams<{
    sequence_name: string;
    frame: string;
  }>();

  const handleToggle = () => {
    console.log("sequenceName: ", sequence_name);
    if (!isChecked && sequence_name) {
      if (
        window.confirm(
          "This will check all sequences as empty and overwrite any existing labels.\n\nAre you sure you want to do this?"
        )
      ) {
        setIsChecked(!isChecked);
        save_nosmoke(token, sequence_name).then((res) => {
          triggerFetch();
          clearSelections();
        });
      }
    }

    if (isChecked && sequence_name) {
      if (
        window.confirm(
          "This will uncheck all sequences and overwrite any existing labels.\n\nAre you sure you want to do this?"
        )
      ) {
        setIsChecked(!isChecked);
        undo_save_nosmoke(token, sequence_name).then((res) => {
          triggerFetch();
          clearSelections();
        });
      }
    }
  };

  return (
    <Wrapper>
      <input
        type="radio"
        checked={isChecked}
        onClick={handleToggle}
        onChange={() => {}}
      />
      {!isChecked ? (
        <span className="text">Check all as empty</span>
      ) : (
        <span className="text">Uncheck all empty</span>
      )}
    </Wrapper>
  );
}

export default NoSmokeRadio;
