import React, { useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import SliderItem from "./SliderItem";
import { Context } from "../context/context";
import { ContextProps } from "../@interfaces/interfaces";
import { SliderProps } from "../@interfaces/other";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { frameMinusOne, framePlusOne } from "../label_processing/helper";

const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  gap: 0.05em;
  padding: 1em;
  align-self: flex-start;
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 500px;
  max-height: 100%;
  flex-shrink: 0;

  @media (max-width: 1000px) {
    max-width: 400px;
  }

  @media (max-width: 700px) {
    max-width: 300px;
    max-height: 500px;
  }

  /* @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  } */

  flex: 0 1 auto;
  // doesnt work if u remove this for some reason
  .container-fullscreen {
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: -100;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
  }

  .main-image-fullscreen {
    max-width: 100vw;
    max-height: 100vh;
    pointer-events: none;
    background-color: black;
    object-fit: contain;
  }

  .scroll--indicator {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 1em;
    cursor: pointer;
  }

  .scroll--indicator__up {
    justify-content: flex-end;
  }

  .scroll--indicator__down {
    justify-content: flex-start;
  }
`;

function Slider({ sliderInfo, markList }: SliderProps) {
  const { currentImageIndex, setFullScreenWidth, setFullImageRatioToOg } =
    useContext(Context) as ContextProps;
  const imageRef = useRef<HTMLImageElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { frame } = useParams<{ frame: string }>();
  const { sequence_name } = useParams<{ sequence_name: string }>();

  useEffect(() => {
    if (imageRef.current) {
      setFullScreenWidth(imageRef.current.width);
    }
  }, [setFullScreenWidth, setFullImageRatioToOg]);

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    let percent = (scrollTop / (scrollHeight - clientHeight)) * 100;

    // if (percent <= 0) {
    //   if (
    //     frame &&
    //     markList.some((mark) => mark.name === frameMinusOne(frame))
    //   ) {
    //     navigate(`/${sequence_name}/${frameMinusOne(frame)}`);
    //   }
    // }
    // if (percent >= 100) {
    //   if (frame && markList.some((mark) => mark.name === framePlusOne(frame))) {
    //     navigate(`/${sequence_name}/${framePlusOne(frame)}`);
    //   }
    // }
  };

  useEffect(() => {
    if (sliderRef.current) {
      // value hardcorded, adjust
      // sliderRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [sliderInfo]);

  // const change

  return (
    <Wrapper>
      <Content ref={sliderRef} onScroll={handleScroll}>
        <div
          onClick={() => {
            if (
              frame &&
              markList.some((mark) => mark.name === frameMinusOne(frame))
            ) {
              navigate(`/${sequence_name}/${frameMinusOne(frame)}`);
            }
          }}
          className="scroll--indicator scroll--indicator__up"
        >
          <IoIosArrowUp />
        </div>
        {sliderInfo.map((item, index) => {
          let acc = 0;
          for (let i = 0; i < markList.length; i++) {
            if (markList[i].name === frame) {
              break;
            }
            acc += markList[i].length;
          }

          return (
            <SliderItem
              active={currentImageIndex === index ? true : false}
              key={index}
              index={index}
              img={item.image}
              name={item.imageName}
              acc={acc}
              widthOfContent={sliderRef.current?.clientWidth}
            />
          );
        })}
        <div className="container-fullscreen ">
          <img
            ref={imageRef}
            className="main-image-fullscreen"
            src={
              "https://images.wallpaperscraft.com/image/single/forest_trees_fog_110131_1920x1080.jpg"
            }
            alt="select"
          />
        </div>
        <div
          onClick={() => {
            if (
              frame &&
              markList.some((mark) => mark.name === framePlusOne(frame))
            )
              navigate(`/${sequence_name}/${framePlusOne(frame)}`);
          }}
          className="scroll--indicator scroll--indicator__down"
        >
          <IoIosArrowDown />
        </div>
      </Content>
    </Wrapper>
  );
}

export default Slider;
