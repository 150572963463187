import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaRegFolder } from "react-icons/fa";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import {
  deleteLock,
  deleteReject,
} from "../networking/sequenceControllerNetwork";

const Wrapper = styled.div`
  padding: 1em;
  border: 1px solid #ccc;
  width: 100%;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-align: center;

  .folder--name {
    width: 100%;
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .folder--img {
    font-size: 1.4rem;
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .folder--link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
    &__disabled {
      color: gray;
      cursor: not-allowed;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .folder--timeout {
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    color: gray;
    gap: 5px;
    pointer-events: none;
    &--delete {
      pointer-events: all;
      &:hover {
        color: white;
        position: relative;
        cursor: pointer;

        &::before {
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${(props) => props.theme.colors.button_red};
          position: absolute;
          content: "Delete lock";
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }

    img {
      width: 21px;
      background-color: inherit;
    }
    &--text {
      font-size: 0.8em;
      text-align: left;
      color: inherit;
      width: 69px;
      text-align: left;
      background-color: inherit;
    }

    &--box {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: inherit;
    }
  }
`;

interface FolderLinkItemProps {
  folder: string;
  folder_group: string;
  lock_timer?: number;
  reviewed?: boolean;
  userThatMadeSeq?: string;
  isAdmin?: boolean;
}

function FolderLinkItem({
  folder,
  folder_group,
  lock_timer = 0,
  reviewed = true,
  userThatMadeSeq = "",
  isAdmin = false,
}: FolderLinkItemProps) {
  const { user, token } = useAuth() as authContextProps;
  const [countdown, setCountdown] = useState<number>(0);
  const [hide, setHide] = useState(false);

  const handleDelete = () => {
    deleteLock(token, folder).then((res) => {
      setHide(!hide);
    });
  };

  const deleteRejected = () => {
    deleteReject(token, folder).then((res) => {
      // refresh site
      setHide(!hide);
    });
  };

  useEffect(() => {}, [hide]);

  useEffect(() => {
    if (countdown === 0 && lock_timer > 0) {
      setCountdown(7200 - lock_timer);
    }
    if (countdown === 1) {
      setCountdown(-1);
    }

    if (countdown <= 0) return;
    const timer = setInterval(() => {
      setCountdown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Wrapper className="folder">
      {countdown > 0 && userThatMadeSeq !== user.username ? (
        <div className={`folder--link folder--link__disabled`}>
          <FaRegFolder className="folder--img" />
          <div className="folder--name ">{folder}</div>
        </div>
      ) : (
        <Link className={`folder--link `} to={`/${folder}`}>
          <FaRegFolder className="folder--img" />
          <div className="folder--name">{folder}</div>
        </Link>
      )}

      {!hide && folder_group === "neoznacene" && lock_timer > 0 && (
        <>
          {lock_timer > 0 && (
            <div
              onClick={handleDelete}
              className={`folder--timeout ${
                userThatMadeSeq === user.username || isAdmin
                  ? "folder--timeout--delete"
                  : ""
              }`}
            >
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAt0lEQVR4nGNgwAIyq3tk0yr71qRV9n8C44q+9Rk1/erY1GLVnFrZ/zy1sq8sqXSyFAinVfSXp1X0vQDJMRACaSCbK/rLsYhXpFX2rSLCgP5PIFvRxUFiqRV9H4kx4D85cpQZMK3GzWdmrdu7aVUu/4nBs2rd306vcfGCGzCr1v3t/d1l/7+eayUK39td9n9WrdsbhAuqXP7/f7eYJAzSg2LAVyJth2EMA/6PuuA/sWkAGeNKcCQBAMHrsUGHHhA6AAAAAElFTkSuQmCC"></img>
                <span className="folder--timeout--text">
                  {Math.floor((countdown % 86400) / 3600)}h{" "}
                  {Math.floor((countdown % 3600) / 60)}m{" "}
                  {Math.floor(countdown % 60)}s
                </span>
              </div>
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVR4nO2VS0oDQRCGPxV0YXTl4w7mEMasRRMfeATRhboRCa40Kx/nEHdKPIb43krIAXxGERdGpeAP9GZmMpMeUJgPajV/1V/dU90NGRn/kDGgClwB74pLYBcYTdt8AWgCPwHxCsylaf4toxNgEhhUFIBTfWsB5TS2vSmDzRDdljQvwIjPBqrOyqOoSbvjs4FrFbVtj2JKWhtMb7ypaK4D7ZC09su88RyjgWFpn3w2cKGiNu1RFKU999nAtoraUYviTNqKzwbGgUcVtqMWREWaBx1dr5SBLxnUNO05RdFZuWlKpMS8brqgq7glTSrMAPch5u0wzbRP4x7gwDG4AdaACectyAPrwK009mbsKbdrDlX0E1gGekO09m1FWsvZ79a85Jh3cg23KThNzCY1HwAaKmIrj8uqcutAf5IGlpx/HrbtQfQBd6qxmKSBYyXbwCVlQzWOkiTXlWzTnpS8czRj8xHjBYx6mq1WRsbf5BdeuXgU/C/QAwAAAABJRU5ErkJggg=="></img>
                <span className="folder--timeout--text">{userThatMadeSeq}</span>
              </div>
            </div>
          )}
        </>
      )}

      {folder_group === "oznacene" && (
        <>
          {!reviewed && (
            <div className="folder--timeout">
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEcElEQVR4nO0aTY8UVbBMv+pZg1HWBfWoR6JR/4IH/ORDveHXCaIXYINeQc7qyYQE/kIn21WzDmNWIxsSNSCIeHEXkJPKwQA32X6vNY/U69ftJMzMzk53zzQbKnnJZvtN1atX31UP4AFsUrARPJ5Q+KZm/FwznjakVjXhbc2o3SK8bUityDfZk7TDvbYDs9AEsMswownf14TfaMb/DKPdyNKM/2rGJR3je7YLrckzEMHDhtTHmvFGcSjCRBOeMaSOimSSONwhN25PAbrVgVn5n3wzrI5pxmX5TcEYqb8MqyNyORNhIuXgDcPqeg8DFzThfhvBYxvFZQm2asYDhvHi/5JSv6dx8Fq9asR4sucGf07j4OWq8Kft4FXD6pcetTtRuXRsB56Sg3sJ/GNIHbQRBDWobGBYHdaMd3Jp2zY8WQnytXbrGRG3l8KKXsDnoGbQbXzesLriaV6TM5RCaCPYniPUhD/ZRdhW2WnXo92BWU34vbeb66IV4yFahplCnRh/tEuwpfLTrneGJdiiCc8VajaOzRSGTWpFgh1MCWwMc4VWMJ4Yw8Vmhj0JmxjFZrR3AOLdRg92eZwgdRAaAobUfG78I6mYIfVJHifKuljN+IEhFclKKNxdBpddBmVYXfZnmx++uQutLFVwItwJJaE3gGrC42XxpRS87m3lxlCpSPKWe4iyROtgxFp4qCcwvzOYMOG3ftP+skTrYETAkPrQ4/sa+oG4WJdWEybjJICTYsRKoMzqm7TvORMO3/IEv4OKoA5GBDThWcHZ14Foxi+8RzgKzWfkuMf5WT+ipx2XHO5pOiOJ1x7DavGej4bUVcdIHO5ovETa+KzXntV+RG/JxyrzqroYsYuwzceTv/sR1Z6RsPGMdKGV9wk2NSO37hvViqTgG6Bam8fYCTuOkXa4t2I3eUpWysFLE3G/Og+IrI5Bw0EPC4hZB9B9PAMNB+1TlDQOdg1KxrKkkWArNBRsltxmSWMXHu27SRrK3hscqIJob4Uof1eB07D6yJ+xO4zwu97LXKzcazGerKiwuuTwxbhvvVL3T6d/FLzSNEbSONjlHdIf644hpLXvffSlKpsPZVXLSvOB1K+ekUOj/GCm6PWyOgwNAZNfMKsrIw+FZD7h1eGONMdgyqAJX9SEa97lbmyUIe3J4gZimIMpgZW8itQ1f7FfjjsfvOCD5LmpNLEjeEQznvdMnB97zuhvY7UYK0SwvfLTDg98P+TjuNIDHzfo8aIVNdML+AJMwCZMTpPU1TVqPV0JYrmNQs2kK05qXtxhJcjv7e8eyQ3bqdMCPFHHMNQ7ACedy1VNXyViu1EG5XEiM+xaZ+9u+lqoWta1lzbmOK8YxA5c7uTTDuNVqcpp8Shjufk8nfE3KBnpWcPqUyl8pIoTty09ALdimJOhUdIO35Y9sjfvE+Rph2F1aDovILrQkq64NJSlBBj0VGPQklTcvU2Jcd9UGOgH0lCWXqxUbZrxK/+A5mbxqIbxpmH1m5Snskf2DqwnHgDc/3AXrBi4/9f1hLQAAAAASUVORK5CYII=" />
                <span className="folder--timeout--text">Need action</span>
              </div>
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVR4nO2VS0oDQRCGPxV0YXTl4w7mEMasRRMfeATRhboRCa40Kx/nEHdKPIb43krIAXxGERdGpeAP9GZmMpMeUJgPajV/1V/dU90NGRn/kDGgClwB74pLYBcYTdt8AWgCPwHxCsylaf4toxNgEhhUFIBTfWsB5TS2vSmDzRDdljQvwIjPBqrOyqOoSbvjs4FrFbVtj2JKWhtMb7ypaK4D7ZC09su88RyjgWFpn3w2cKGiNu1RFKU999nAtoraUYviTNqKzwbGgUcVtqMWREWaBx1dr5SBLxnUNO05RdFZuWlKpMS8brqgq7glTSrMAPch5u0wzbRP4x7gwDG4AdaACectyAPrwK009mbsKbdrDlX0E1gGekO09m1FWsvZ79a85Jh3cg23KThNzCY1HwAaKmIrj8uqcutAf5IGlpx/HrbtQfQBd6qxmKSBYyXbwCVlQzWOkiTXlWzTnpS8czRj8xHjBYx6mq1WRsbf5BdeuXgU/C/QAwAAAABJRU5ErkJggg=="></img>
                <span className="folder--timeout--text">{userThatMadeSeq}</span>
              </div>
            </div>
          )}
          {reviewed && (
            <div className="folder--timeout">
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcUlEQVR4nO2aT2sTURDAX1UUBUHwoCLqwU+gotRKM7NRtEhRL28m6s1j/RD15J+j1W8geiieVESwzcxaEUUvgp48FLEtohfxIlhoZJK2lpVKdtPsbvQNDCSbN39+M2/ehmWd65JEkxf2gdBdFP5iap9PTPm9rpekOuF3o9InVG6sVBD+OBhf3OV6RUDodit5emhQpqD0aPHarVyT8e/8RhC+gUJzycq2qwaw5G8w5j1Z/YDQLAhdt5xSg5hh1sBNFXqW9InKUx35VLqWGiRtJ1IH+A2Xpjhz3Q2QF4hmiPPfgmBO6gKIFt8FDB1xYUYaYWtpmJG/S9GnE4ZTKyFFVx5DRxKSV4VB+VtU94ewTsd6uSPzWKdTFq8a08HeBYlrly3W8afntqPyh14FGVt+NqCs7dq5MoGA8hMQ2OAarg+V76SxdWUBAeH3IGe3tWLQaFp7Vw4Q+hpN8n7zD8oehRdKARLFPFDR2gFUmm4D4kdF/dEmhFC/fc9SDNcNkIrSYVtbnajtAKWXq64VXgDx55efCSt9ztpV1w0QFP4eKZ229UOPhzah8r1V5uKKrRl4fmYrCL3tZHu6roC0dB6VRppGDddnSSd+H7frftyvR+EHnc0Yd3/YQeima4yuM9tI/SUU+olKr4ffDG9Z9DfWKQTmdWqB8v3+F35zEyauVW12Wr5oZC0gMM/jF4RfgfidS36imE+2tl+PgWBTaboa+yN2qtm/27WCwPLcEANII3QES7CNMMzICim68hg6kpCiK4+hIwn5ZzoCQrNFJ40JBeWZ1CD2lkHRieOfejU1iD1vMpgydAaUZwwi07soQVwx8gtC2/01uVfqvQAAAABJRU5ErkJggg=="></img>
                <span className="folder--timeout--text">Accepted</span>
              </div>
              <div className="folder--timeout--box">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVR4nO2VS0oDQRCGPxV0YXTl4w7mEMasRRMfeATRhboRCa40Kx/nEHdKPIb43krIAXxGERdGpeAP9GZmMpMeUJgPajV/1V/dU90NGRn/kDGgClwB74pLYBcYTdt8AWgCPwHxCsylaf4toxNgEhhUFIBTfWsB5TS2vSmDzRDdljQvwIjPBqrOyqOoSbvjs4FrFbVtj2JKWhtMb7ypaK4D7ZC09su88RyjgWFpn3w2cKGiNu1RFKU999nAtoraUYviTNqKzwbGgUcVtqMWREWaBx1dr5SBLxnUNO05RdFZuWlKpMS8brqgq7glTSrMAPch5u0wzbRP4x7gwDG4AdaACectyAPrwK009mbsKbdrDlX0E1gGekO09m1FWsvZ79a85Jh3cg23KThNzCY1HwAaKmIrj8uqcutAf5IGlpx/HrbtQfQBd6qxmKSBYyXbwCVlQzWOkiTXlWzTnpS8czRj8xHjBYx6mq1WRsbf5BdeuXgU/C/QAwAAAABJRU5ErkJggg=="></img>
                <span className="folder--timeout--text">{userThatMadeSeq}</span>
              </div>
            </div>
          )}
        </>
      )}

      {folder_group === "rejected" && (
        <div
          className="folder--timeout folder--timeout--delete"
          onClick={deleteRejected}
        >
          <div className="folder--timeout--box">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnklEQVR4nO1bO2gUQRgeDT6Q4ANRBEEUBAULBRuLFFqoCRFuvn8dKzGFRC01lgoRfGDhI4igabU0aSPaKGhhYaEoaKOiKARFRMRc/rnEkbnL+bzkbm9nd3Y398EHC/eY//v+2Z3Z/98VooUWWmihhRZaSBpGqSVayg5LUygsFbMFprNzAQMXGBjTRMaSgSIDA/YzkWcYIeZoYKgqvAaH7XdEXsFBsH8G8ZXZEAT7RR5h9uxZpIG39QzQwHujVLvIGxg4U1f872vCaZEnjCm1hom+hzCgWFRqncgL9MwXvul4S+QBWsoOJvrRhAGmFATbRZZh+vvnaqLHzYif4hOjVJvIKrSURyKIr64Kh0UWYTo7FzPRaFQDGPiYya0yE12KnP3fJlwUWUJRqfUMsEMD9LhSG0RWwEQjrsT/MoHorsgCSlJ2hRD2TAPPG14WpewSaYY5dGieBl40mNHb9s7PkoE7Da4IL+wYIq3QwNEQ5/Wp6u/sccOzBjgq0gij1Aom+hK3AQx8NUqtEmmDBgZDXtmbmwGV0+e6SBMY2MzARGIGAJNayq0iLWDgXhgBUQ2YmgUPUlE+Y0CFDd6FAZbjwF6v4k1Pz0JN9MaXAZronS21eTNAE51sMnBXBlie8CL+u1KrGfjm2wBbarMlt8QN0MDNCFlzOQPs5uhG0uK3NVvmisOAcixSdiQi3lT27o8iZcz1DKjwsS3BxW4AB0GPg2DjMMD+54FYxRul2jXRh9QaQDRqS3GxGcDAOReBxmXAlAlnYxFfVGqd7dhkwIBxW5JzboAmGnYVZJwGlAkMORVfItrhNMC4DbDlM2CnE/FGqTYNPHUd4J+bl6ibqmn+/6mTrpJ20d2pPQMmy8KBm+XjGMawsUfLfnf3Mib6FEtwCZCBz0bK5U0bwMCAbxGRTSC63JT4caKNtiOTAwNKHASbUtHd0REbIxFMGAklvgR0JxBU+MZIBFpNDYk3Ss3XwMvYDYh5H/AfgZdWW10DNNHx2IPxYUCFfTNnH1gZpruTNQPqdpV0yO5OqneC0487WFM8FwpbwnZ3Ur8TrD3uhNX6vwFE9xPLgmcy8PCvrhIHwT7fQXkwQf15t/fad0CJE3hVvlssEe32HownloJgl133+3wH4nEWHBMsZa/3QDyRiQ5WHmVPcPlLC+3SWywU1laXwKuzMPtX/n6Ti+ha1H5fVjJvxdd83K6o1Hr7dLZ9vYWJzueKVpOUvf++ifITl79HwYhB+g0AAAAASUVORK5CYII="></img>
            <span className="folder--timeout--text">Need fix</span>
          </div>
          <div className="folder--timeout--box">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVR4nO2VS0oDQRCGPxV0YXTl4w7mEMasRRMfeATRhboRCa40Kx/nEHdKPIb43krIAXxGERdGpeAP9GZmMpMeUJgPajV/1V/dU90NGRn/kDGgClwB74pLYBcYTdt8AWgCPwHxCsylaf4toxNgEhhUFIBTfWsB5TS2vSmDzRDdljQvwIjPBqrOyqOoSbvjs4FrFbVtj2JKWhtMb7ypaK4D7ZC09su88RyjgWFpn3w2cKGiNu1RFKU999nAtoraUYviTNqKzwbGgUcVtqMWREWaBx1dr5SBLxnUNO05RdFZuWlKpMS8brqgq7glTSrMAPch5u0wzbRP4x7gwDG4AdaACectyAPrwK009mbsKbdrDlX0E1gGekO09m1FWsvZ79a85Jh3cg23KThNzCY1HwAaKmIrj8uqcutAf5IGlpx/HrbtQfQBd6qxmKSBYyXbwCVlQzWOkiTXlWzTnpS8czRj8xHjBYx6mq1WRsbf5BdeuXgU/C/QAwAAAABJRU5ErkJggg=="></img>
            <span className="folder--timeout--text">{userThatMadeSeq}</span>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default FolderLinkItem;
