import React, { useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  font-weight: 100;
  margin: 0.5em;
  gap: 5px;
  .input {
    padding: 0.2em;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

interface InputProps {
  label: string;
  type: string;
  parentFunction: (value: string) => void;
  parentFunctionOnEnter?: () => void;
}

function Input({
  label,
  type,
  parentFunction,
  parentFunctionOnEnter,
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string | undefined = inputRef.current?.value;
    if (newValue) parentFunction(newValue);
  };

  const onEnter = (e: any) => {
    if (e.key === "Enter" && parentFunctionOnEnter) parentFunctionOnEnter();
  };


  return (
    <Wrapper>
      <label>{label}</label>
      <input
        className="input"
        type={type}
        ref={inputRef}
        onKeyDown={onEnter}
        onChange={handleInputChange}
      />
    </Wrapper>
  );
}

export default Input;
