import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .button {
    all: unset;
    font-weight: 400;
    color: white;
    background-color: #417690;
    cursor: pointer;
    font-size: 1rem;
    margin: 0.5em;
    padding: 0.4em 0.4em;
    border-radius: 4px;
    &:hover {
      background-color: #609ab6;
      transition: all 0.2s;
    }
    &:focus-visible {
      outline: none;
      border: 2px solid black;
    }
  }

  .button--disabled {
    background-color: gray;
    cursor: not-allowed;

    &:hover {
      background-color: gray;
    }
  }

  .button--red {
    background-color: ${(props) => props.theme.colors.button_red};

    &:hover {
      background-color: ${(props) => props.theme.colors.button_red_hover};
    }
  }
`;

interface ButtonProps {
  children: string;
  parentFunction?: () => void;
  red_color?: boolean;
  disabled?: boolean;
}

function Button({
  children,
  parentFunction,
  red_color,
  disabled,
}: ButtonProps) {
  const handleClick = () => {
    parentFunction?.();
  };

  //fix enter key press
  const handlePress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    //if (e.key === 'Enter') parentFunction?.();
  };

  return (
    <Wrapper>
      <button
        disabled={disabled}
        onKeyDown={handlePress}
        onClick={handleClick}
        className={`button ${red_color ? "button--red" : ""} ${
          disabled ? "button--disabled" : ""
        }`}
      >
        {children}
      </button>
    </Wrapper>
  );
}

export default Button;
