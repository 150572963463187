import React, { useContext, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/context";
import { SliderItemProps } from "../@interfaces/other";
import { ContextProps, ImageRect } from "../@interfaces/interfaces";
import Canvas from "./Canvas";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 500px;

  @media (max-width: 1000px) {
    max-width: 400px;
  }

  @media (max-width: 700px) {
    max-width: 200px;

    .slider-photo {
      border-radius: 10px;
      width: 50px;
      vertical-align: top;
      object-fit: contain;
      object-position: top;
    }
  }

  .slider-item {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    box-sizing: border-box;
    padding: 0.1em;
    gap: 1em;
    border-radius: 10px;
    background-color: transparent;
  }

  .slider--image--box {
    position: relative;
    width: fit-content;
  }
  .slider-item__selected {
    .slider--title {
      &::before {
        position: absolute;
        top: 10px;
        content: "SELECTED";
        font-style: italic;
        font-weight: 300;
        color: white;
        background-color: #609ab6;
        border-radius: 4px;
        padding: 2px 4px;
      }
    }
  }
  .slider-item__marked {
    .slider--title {
      overflow-wrap: break-word;
      &::before {
        position: absolute;
        top: 75px;
        content: "MARKED";
        font-style: italic;
        font-weight: 300;
        color: white;
        background-color: #92ba9a;
        border-radius: 4px;
        padding: 2px 4px;
      }
    }
  }

  .slider-item__empty {
    .slider--title {
      &::before {
        position: absolute;

        top: 75px;
        content: "EMPTY";
        font-style: italic;
        font-weight: 300;
        color: white;
        background-color: gray;
        border-radius: 4px;
        padding: 2px 4px;
      }
    }
  }

  .slider--index {
    width: 35px;
    @media (max-width: 1000px) {
      font-size: 0.8rem;
      width: 25px;
    }
    @media (max-width: 700px) {
      font-size: 0.55rem;
      width: 30px;
    }
    font-weight: 400;
    // add opacity
    color: rgb(0, 0, 0, 0.5);
  }

  .slider-photo {
    flex-shrink: 0;
    border-radius: 10px;
    width: 200px;
    vertical-align: top;
    object-fit: contain;
    object-position: top;

    @media (max-width: 1000px) {
      width: 150px;
    }
  }
`;

function SliderItem({
  active = false,
  img,
  name,
  index,
  acc,
  widthOfContent,
}: SliderItemProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const { setCurrentImageIndex, fullScreenWidth } = useContext(
    Context
  ) as ContextProps;
  const [scale, setScale] = useState(1);
  const [rect, setRect] = useState<ImageRect>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const handleClick = () => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setRect({
        top: Math.round(rect.y),
        left: Math.round(rect.x),
        width: rect.width,
        height: rect.height,
      });

      setScale(1 / +(fullScreenWidth / imageRef.current.width).toFixed(1));
    }
  }, [fullScreenWidth, img]);

  return (
    <Wrapper onClick={handleClick} style={{ width: widthOfContent }}>
      <div className={`slider-item ${active && "slider-item__selected"}`}>
        <div className="slider--index">{index + acc + 1}</div>
        <div className="slider--image--box">
          <img ref={imageRef} className="slider-photo" alt="idk" src={img} />
          <Canvas
            rect={{
              top: 0,
              left: 0,
              width: rect.width,
              height: rect.height,
            }}
            scale={scale}
            index={index}
            strokeWidth={1}
          />
        </div>

        <div className="slider--title">{name}</div>
      </div>
    </Wrapper>
  );
}

export default SliderItem;
