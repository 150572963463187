import React, { useState } from "react";
import { checkNewPolygon } from "../label_processing/label_processing";
import {
  fetchUnmarkedMarkedList,
  fetchSequence,
} from "../networking/sequenceControllerNetwork";
import {
  Selection,
  ContextProps,
  SequenceDataProps,
  Mark,
} from "../@interfaces/interfaces";
import { adjustToScale } from "../label_processing/label_processing";

import { frameMinusOne, framePlusOne } from "../label_processing/helper";

export const Context = React.createContext<ContextProps | null>(null);

const ContextProvider = ({ children }: any) => {
  const [sequenceDataPrev, setSequenceDataPrev] = useState<SequenceDataProps>({
    sequenceName: "",
    images: [],
  });
  const [selectionsPrev, setSelectionsPrev] = useState<Selection[]>([]);

  const [sequenceDataNext, setSequenceDataNext] = useState<SequenceDataProps>({
    sequenceName: "",
    images: [],
  });
  const [selectionsNext, setSelectionsNext] = useState<Selection[]>([]);

  const [sequenceData, setSequenceData] = useState<SequenceDataProps>({
    sequenceName: "",
    images: [],
  });
  const [selections, setSelections] = useState<Selection[]>([]);

  const [fullImageRatioToOg, setFullImageRatioToOg] = useState<number>(1);
  const [fullScreenWidth, setFullScreenWidth] = useState<number>(1);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [currentImageRect, setCurrentImageRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const [prev, setPrev] = useState<Selection[]>([]);

  const addNewSelection = (newSelection: Selection, action: string) => {
    if (
      JSON.stringify(newSelection.selection.edges[0]) ===
      JSON.stringify(newSelection.selection.edges[2])
    ) {
      return 0;
    }
    const currentImageSelections = selections.filter(
      (el: Selection) => el.imageId === currentImageIndex
    );
    newSelection.selection.selectionId = currentImageSelections.length;

    const intersection = checkNewPolygon(
      newSelection,
      currentImageSelections,
      action
    );

    if (intersection === 0) {
      if (action !== "delete") {
        setSelections((prevItems: Selection[]) => [...prevItems, newSelection]);
      }
    } else {
      setSelections((prevItems: Selection[]) => [
        ...prevItems.filter((item) => item.selection.edges.length > 0),
      ]);
    }
  };

  const copyPreviousToCurrent = (prev: Selection[]) => {
    const currentImageSelections = selections.filter(
      (el: Selection) => el.imageId === currentImageIndex
    );

    // deep copy prev into setselections but change the imageId to currentImageIndex
    if (
      prev.length > 0 &&
      (currentImageSelections.length === 0 ||
        currentImageSelections.filter(
          (item) => item.selection.edges.length === 0
        ))
    ) {
      const newPrev = JSON.parse(JSON.stringify(prev));
      newPrev.forEach((item: Selection) => (item.imageId = currentImageIndex));
      setSelections((prevItems: Selection[]) => [...prevItems, ...newPrev]);
    }
  };

  const getUnmarkedMarked = async (
    token: string,
    sequence_name: string
  ): Promise<{ marked: Mark[]; folder_type: string }> => {
    let data = { marked: [], folder_type: "" };
    try {
      const response = await fetchUnmarkedMarkedList(token, sequence_name);
      data.marked = response.data.marked;
      data.folder_type = response.folder_type;
    } catch (error) {
      console.log(error);
    }
    return data;
  };

  const fetchAndSetImages = async (
    token: string,
    sequence_name: string,
    frame: string,
    setSequenceDataFunction: React.Dispatch<
      React.SetStateAction<SequenceDataProps>
    >
  ): Promise<Selection[]> => {
    try {
      const response = await fetchSequence(token, sequence_name, frame);

      if (response.data.images) {
        const base64Mod = response.data.images.map(
          (item: { imageName: string; image: string }) => {
            return {
              imageName: item.imageName,
              image: "data:image/jpeg;base64," + item.image,
            };
          }
        );
        setSequenceDataFunction({
          sequenceName: response.data.sequenceName,
          images: base64Mod,
        });
      }
      return response.data.selection[0].selections;
    } catch (error) {
      return [];
    }
  };

  const switchExistingStatesFetchNewImagesDependingOnDirection = async (
    token: string,
    sequence_name: string,
    frame: string,
    ratioOg: number,
    diff: number
  ) => {
    switch (diff) {
      case 1:
        const sequenceDataCopy = JSON.parse(JSON.stringify(sequenceData));
        const selectionsCopy = JSON.parse(JSON.stringify(selections));

        const sequenceDataNextCopy = JSON.parse(
          JSON.stringify(sequenceDataNext)
        );
        const selectionsNextCopy = JSON.parse(JSON.stringify(selectionsNext));

        const doCurrent = async () => {
          return fetchAndSetImages(
            token,
            sequence_name,
            framePlusOne(frame),
            setSequenceDataNext
          ).then((result) => {
            setSelectionsNext(adjustToScale(result, 1 / ratioOg));
          });
        };

        const doPrev = async () => {
          setSequenceDataPrev(sequenceDataCopy);
          setSelectionsPrev(selectionsCopy);
        };

        const doNext = async () => {
          setSequenceData(sequenceDataNextCopy);
          setSelections(selectionsNextCopy);
        };

        return await Promise.all([doCurrent(), doPrev(), doNext()]);

      case -1:
        const sequenceDataPrevCopy1 = JSON.parse(
          JSON.stringify(sequenceDataPrev)
        );
        const selectionsPrevCopy1 = JSON.parse(JSON.stringify(selectionsPrev));

        const sequenceDataCopy1 = JSON.parse(JSON.stringify(sequenceData));
        const selectionsCopy1 = JSON.parse(JSON.stringify(selections));

        const doCurrent1 = async () => {
          return fetchAndSetImages(
            token,
            sequence_name,
            frameMinusOne(frame),
            setSequenceDataPrev
          ).then((result) =>
            setSelectionsPrev(adjustToScale(result, 1 / ratioOg))
          );
        };

        const doPrev1 = async () => {
          setSequenceData(sequenceDataPrevCopy1);
          setSelections(selectionsPrevCopy1);
        };
        const doNext1 = async () => {
          setSequenceDataNext(sequenceDataCopy1);
          setSelectionsNext(selectionsCopy1);
        };

        return await Promise.all([doCurrent1(), doPrev1(), doNext1()]);
    }
  };

  const setImagesAndSelections = async (
    token: string,
    sequence_name: string,
    frame: string,
    diffFs: number,
    prevFrame: string,
    ratioOg: number
  ): Promise<void> => {
    let diff = 0;
    if (diffFs === 0 && frame.length > 0 && prevFrame.length > 0) {
      const new_frame_number = frame.split("image_")[1].split("-frame")[0];
      const prev_frame_number = prevFrame.includes("image_")
        ? prevFrame.split("image_")[1].split("-frame")[0]
        : "";
      let strNumber = parseInt(new_frame_number, 10);
      let strPrevNumber = parseInt(prev_frame_number, 10);
      diff = strNumber - strPrevNumber;
    } else {
      diff = diffFs;
    }

    const inverse_ratioOg = 1 / ratioOg;

    if (Math.abs(diff) !== 1) {
      fetchAndSetImages(token, sequence_name, frame, setSequenceData).then(
        (result) => setSelections(adjustToScale(result, inverse_ratioOg))
      );
      fetchAndSetImages(
        token,
        sequence_name,
        frameMinusOne(frame),
        setSequenceDataPrev
      ).then((result) => {
        setSelectionsPrev(adjustToScale(result, inverse_ratioOg));
      });
      fetchAndSetImages(
        token,
        sequence_name,
        framePlusOne(frame),
        setSequenceDataNext
      ).then((result) => {
        setSelectionsNext(adjustToScale(result, inverse_ratioOg));
      });
    } else {
      switchExistingStatesFetchNewImagesDependingOnDirection(
        token,
        sequence_name,
        frame,
        ratioOg,
        diff
      ).then(() => {
        return Promise.resolve();
      });
    }
    return Promise.resolve();
  };

  const clearSelections = () => {
    setSelections([]);
    setSelectionsNext([]);
    setSelectionsPrev([]);
  };

  const clearImages = () => {
    setSequenceData({
      sequenceName: "",
      images: [],
    });
    setSequenceDataNext({
      sequenceName: "",
      images: [],
    });
    setSequenceDataPrev({
      sequenceName: "",
      images: [],
    });
  };
  return (
    <Context.Provider
      value={{
        switchExistingStatesFetchNewImagesDependingOnDirection,
        selections,
        currentImageIndex,
        currentImageRect,
        fullImageRatioToOg,
        fullScreenWidth,
        sequenceData,
        prev,
        setSelections,
        addNewSelection,
        setCurrentImageIndex,
        setCurrentImageRect,
        setFullImageRatioToOg,
        setFullScreenWidth,
        copyPreviousToCurrent,
        fetchAndSetImages,
        setSequenceData,
        clearImages,
        clearSelections,
        setPrev,
        getUnmarkedMarked,
        setImagesAndSelections,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
