import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useParams } from "react-router-dom";

const decayingBackground = keyframes`
  from {
    background: #417690;
    color: white;
  }
  to {
    background: white;
    color: black;
  }
`;

const Wrapper = styled.div`
  width: 250px;
  position: relative;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .x--button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 4px 8px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .controls--button {
    display: flex;
    margin-top: 25px;
    gap: 5px;
    flex-shrink: 0;
  }

  .controls--button--item {
    font-weight: bold;

    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #b0b0b0;
    }
    &--active {
      background-color: #264b5d;
      color: white;
    }
  }
  .controls--display--box {
    margin-top: 30px;
    flex-shrink: 0;
  }

  .controls--display {
    margin: 20px 0;
    border: 1px solid black;
    animation: ${decayingBackground} 0.5s ease-in-out forwards;
  }

  .controls--box {
    overflow: scroll;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      margin: 20px 0;
      flex-shrink: 0;
      & div {
        display: flex;
        gap: 7px;
        font-weight: 300;
      }
    }

    & span {
      font-size: 0.8rem;
      font-weight: 200;
      align-self: center;
    }
    & img {
      width: 30px;
    }
  }
`;

const ControlsDisplay = styled.div<{ $isActive?: boolean }>`
  margin: 20px 0;
  padding: 5px 20px;
  border: 1px solid black;
  border-radius: 5px;
  animation: ${(props) => (props.$isActive ? decayingBackground : "none")} 1s;
`;

interface FullscreenControlPanelProps {
  height: number | undefined;
  seq_name: string | undefined;
  frame_name: string | undefined;
  index: number;
  total_frames: number;
  toggle_fullscreen: () => void;
}

function FullscreenControlPanel({
  height,
  seq_name,
  frame_name,
  total_frames,
  index,
  toggle_fullscreen,
}: FullscreenControlPanelProps) {
  const [page, setPage] = useState("controls");
  const [isActivatedFrame, setIsActivatedFrame] = useState(false);
  const [isActivatedSeq, setIsActivatedSeq] = useState(false);

  const { frame } = useParams<{
    frame: string;
  }>();

  const handleClickExit = () => {
    toggle_fullscreen();
  };

  const handleClickInstructions = () => {
    setPage("instructions");
  };

  const handleClickControls = () => {
    setPage("controls");
  };

  useEffect(() => {
    setIsActivatedFrame(true);
    setTimeout(() => {
      setIsActivatedFrame(false);
    }, 500);
  }, [index]);

  useEffect(() => {
    setIsActivatedSeq(true);
    setTimeout(() => {
      setIsActivatedSeq(false);
    }, 500);
  }, [window.location.href]);

  return (
    <Wrapper
      style={{
        height: `${height}px`,
      }}
    >
      <img
        onClick={handleClickExit}
        className="x--button"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiElEQVR4nO2aSWsUURDHf8GYjChqJmbBkx4lon4Jl7jG3OJyU+LFhejV5ayeAgE/hxJDAipx342KS0w8uRxEvRkVlZHCf0OhM5Punl5G8Q8Nmbx6r+p11avtNfzHv4si0AOcAoaB58BH4Kse+/uZxoxmG9BCnaAA7AbGgB9AKeLzHRgFdgHNeWxgHnAYeOuE+gJcBI5KMyv0xufqadH/bOwYcElzgvlvgAG9nEywCXjpBLgD7AEWxVhrMbAXuOvWmwa6SRH2ps44hveAdQmuvwF44NYfSkM7nRLcGHwC9gNzkmbCrzUPAjNO2x1JLb5c6i7J66wkfawCJsVzSjLUhDa34G1gCdmhBbgq3i9lFbFQcOZ0HZhP9pgP3HRmFuvMnHHmZMEuL7Q6qzAHENnFBgc7izMR5szMSCbzbqGDXRAnzDvVCw65wx/KxI64OJGGi42LRmBCstmmqqJZqYIRr6X+sFGyvZ1NK7uch4iDTqUbN0gHDc6T7qhGOCYiy53ibOKp5t8X0zTQLx4jlQiKSqu/xEgAO9wmntUSvEIGSqtvvlWSc7sEuUA0tAOPNdeKqaWkj3Hx21Ju8LQGrZ6IooknGWnC44R4nqQMhjW4lfB5WNaa+N16zlIGLzRoVVwUc8pSEwG63Av8Ax80WAyhiUc5aSLAEvF/Rxl81WATldHgNpHmc4XZA3fQJ4i9kYcZbORyLRuJY1qTOZlWWzXT+mcO+7AGrQMY1f1mrZneau43CIjWPPurA2KPBq1bGAXtOWhmXPw2V0rGgqTROoD1mjQWXdK4sBLRqISxNmYtabxVcmlhn3icr0a0U0RWHBFzMxNq4aSBBtU6JmPfbIHmtQjXU3/YLNlehbmGGHBVXr01Hx5KtgNhJhRcr9cayvWCAecZQ18KdWvSjJpjeWMN8FkyRb7KGHJvwNqWeaFNTTmTZTDOAgW1hUryQnk0sRcAtyTDrVruGduUmAXXCvY7KxSBa+I9ncSFz3KnWjOz1WRzJqbE07LyZUkt3OHMbEa9V3OHSaNR3umzMyfL5RJFwTmAIA3pTjBi21WGrz4H07573+DUHnTt+2N+xVBU7hSkHSWZUpK3xbNq55BLZ0rKSC29Pq6+U5fcdpOeVl0a9Ypm3PUJgrTjQF5fQDSrKz6iEiBqk+GbKtO+vDZQDovUi7Wq7Zzqkvfuo5r3SvPPimZLtXriP/jL8RMhpXAUmuIBHwAAAABJRU5ErkJggg=="
      />
      <div className="controls--display--box">
        <ControlsDisplay $isActive={isActivatedSeq}>
          {frame?.replace("image", "sequence").split("-")[0]}
        </ControlsDisplay>
        <ControlsDisplay $isActive={isActivatedFrame}>{`Frame ${
          index + 1
        } of ${total_frames}`}</ControlsDisplay>
      </div>

      <div className="controls--button">
        {/* <div
          onClick={handleClickInstructions}
          className={`controls--button--item ${
            page === "instructions" && "controls--button--item--active"
          }`}
        >
          Instructions
        </div> */}
        <div
          onClick={handleClickControls}
          className={`controls--button--item ${
            page === "controls" && "controls--button--item--active"
          }`}
        >
          Controls
        </div>
      </div>
      {page === "instructions" && (
        <div>
          <div></div>
          <div>All labels are automatically saved.</div>
        </div>
      )}
      {page === "controls" && (
        <div className="controls--box">
          <div>
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgklEQVR4nO2ZXWiOYRjHf4wNsxQzJW3MkZwoMR/zNZ9H5GBsSjlgWEvJgSg5UMpHOaANp2y+zpQ4csCICDVj0hwoDJN8ZsNeXfV/6m7N632+3ved3n/dB9f13Pd1P//nvq/rvp77gtQwFXgEtAFDGKRYAnwAEsD9wUpkOfBdJK4CYxiEWOSQaAKGSr8QmPyPbVgPtAAPgG6gV61bumZgO1AeN4mpmtRInNR2snZQutZ+/fOAWuCWnqfa+mSrRjYixQg5tU10WSthJE5J9wvY4PRfAXSk+OKTgEpgB3AF+Ok8ewIsjZLIURluB4qkOyxdr76eYSRw2ucK9McEYCfwyunTpI8ZChXAb32pmdJt1AQ9wBrpxgP3fJIYiIgH+2BH9KGs312gOAyRmzJkvuD5ylfp6hwSTwOQSEbEw2ygy9lqgcisloEuZ0tdl+6ss52CrESqRFAke6b+d4Jssxsa3CB5reS3wFjp/PpEECKGicAbjWnEB2Zo0EegUFGqXTqL9150SqSJiGGB4zNV+IxUxySvlPwSGKYY35FmIoZ9TgRN6Zzp1IC5ki9J3i25NgISQYgUOs6/7l+dp6njax1+NvibwrAdYAQ4saMigg7PhCJqUmxSx/OSVzkRwwvBfRkkUgT80Dsky/E4oUl2ST4g+ZDk+ohIBCViuKbxW0mC/cpyp0veDHwCZkluyQIiDf3Os78iP8mzB1lApML5sQuM7iwgUqLx78IQ6ckCIgUab04fGIksIEIUNnJEyK3IgMhtrTh8JKMBI/G/Eck0EjkiQm5FIkYi7Nbycq1kGXLcKIgi1/Ky31A3fyFREkX2+1BGvB+tTGBOFP8jzTKyhcxhq97hTBgj22XkHJnDxX73z4FQrhuMz8Ao0o/Ruki3dygLa6xVX8QuJtKNOs1td9OhUSNjz4HhpDfsvtDc1VEYzFO9wr1GTQf2as42pxgbGstktFeV3bgxTwegr9v4VNEkw3axXEp8KFNdxuY6HscEI1Tf85Y7DjKlwGPNcVt+EguKHX+xlZkfoe1KZyWMzDhiRrFu671Ebk/IpDJfjt3jrETsJNxt1uik152K+VZf8XPY1Tkhtk8+Edt2SoYqp95o7QtwQflRhTLXfLUS6bYp7fBK357PLSbDsBi/XlUlP0WhPp3Y1VGeE0SEKVoNq2VY2m3/M7b3rb2XzrJY21JJK1B+8AfvKbgQNhxWwQAAAABJRU5ErkJggg==" />
              <span>and</span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoUlEQVR4nO2Zi2rCQBBFzx+Y2vio/f8/sFAKLVLRPpTWZ1v9mZSFCVyEtibRZLfsgYWYiDvXccc7uxCJBEtiI2gS4AV4Ay4JWMQrkNl4D1HMBTATESomJRBSYGGBf4mI/HoRgpgOsJTAByKkB2zs+gPo4zEzC3QrgeZCsHtbe+3Wj7c8A3OgK/dUCPbMveeJwMgOhARLFoV4RsyIb8SM+MYYeMQT2zEGhg3MPTQH4GKoRCreaUr9TG3uVRWjmZiVyA3gFfXTAdYWw7KMmEQM4K4hEerNNtICHN2ctaSz25kVb5qeuGbXnLWLtqdFRplicFNyrtlfGxr6myw6ypTYccm51sdUssoL7AwkZQtP6QV2BpKqhadbdIGdgZas2X2VwjOwb8F90Ij6GZ1CRM61ZWZC/UxsbhdD49wBt/wDstize0bMiG/EjPhGkBkZAg920POTEPfs3vf/lqUFPRdvpkLa4mJdH+4t/QOjmYoQPY7b+2I7fmNwcMyWC9HjOB/a56PQYzYdQYnQfkbb5s8QRaiYlYlockvpJHROseUZidAM34Mx2koMz5+8AAAAAElFTkSuQmCC" />
            </div>
            <div>Draw bounding box</div>
          </div>
          <div>
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADg0lEQVR4nO2aTWxMURiGHxrT0rLpqEREqcRG7BTRBqmfEBKr0m5s0GpDSCxEFzYije6EaNjSSruSEDsRVClSoUVDWPmp6sJPQ4vMlZO8V85CpnPnnjszlXmTk/R8Pfc933vP33fPN5C7uAc8AZYwxdEHeMAosI4pjNnAFYkZB7ZlqmMzBZqBTqBfb/KnyqhsHUATUJGEpwKo0t8FwHmJ+Q6sjcp501E9cFedpVoSQA9QJw4bvWpzEpim4ov5BCx2LWIzMGQ59wu4BhwEqoEFKYp6DmyweOs1iuZ/5yRkOnBVNrMBFLkQMBO4YDnyDjgMzPtH2yCj1G45uEVTydhbZZsDvJCtLayIucBDkU2IsCRJey9g6QPienar+vA0SgaVGvnfwMowIvw3MgysSOGZoEI8TTVfTJNs36y10SrbrXSnkz8SQwEWnJdmuW9Ns8uy3bC25Y+ybQ8qxF8T74H5AZ5LV4inhW5QCozItkO2Q6rfDLo7edpJzG5EhoR4QI14Dqj+VLuYGZXPsi1PxZECa4ttCSjChZBn8mEG8Fa2TeI+rfqpVBypt6bUrCwI8YCd4mpRvUv1KtVfpuKIf2KboSVLQu6Ia6EigjG9VHNIflCbpZPFTubBH0BxFoUkgEXieyCbWbcG3arvTuZEsxpdT1OEKyEe0Ci+NtVPqH5E9bPJnOhUo+YcEHJJfOY0/wLsUX2ZQpnjyZzoF0llDgh5lKSP2GROjIokngNCRkL48Ddgi+WAkPEwQnwSFxwuStrIC7GQHxHyUyvaaZETi937X4RkG15eiJAfEcfwwk4tF7FWWBS6iLVcRL9hUeYi+n3s4HskLFan8D0yKTpEso/soVE+XAxD4t+7mivLbKFbPjSEIanQDcbXNO+0wqJEVz/Gh/KwZD16I3vJPBrU920XZHUie6Vry0xuu2/Ud60LwgLlKwzhUTKHFvU5oBtFJ9ho3cZHllW1sEYHoH0b7wztVqbK3MFGhXIriXMmig6KrF8iDEQkxnAOqo9erZNIELfWy7CV1HeBamskBpWlihRx5ff8QO5YyKAypoU9YY1EKRlCkfJ7fnj9Wnt+ccDDrsHaYhNaE4VkATVKjXlWCrlL8dEqRa4xlTLZ9ivsGLOeM2tuPVnGdGCXskqJABcJCZ3YtS7PCRzB5N7NaJhchgm7zfeMmfummB/DGJuJYs2U8jNQzvAHU963tC5krbYAAAAASUVORK5CYII=" />
              <span>and</span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoUlEQVR4nO2Zi2rCQBBFzx+Y2vio/f8/sFAKLVLRPpTWZ1v9mZSFCVyEtibRZLfsgYWYiDvXccc7uxCJBEtiI2gS4AV4Ay4JWMQrkNl4D1HMBTATESomJRBSYGGBf4mI/HoRgpgOsJTAByKkB2zs+gPo4zEzC3QrgeZCsHtbe+3Wj7c8A3OgK/dUCPbMveeJwMgOhARLFoV4RsyIb8SM+MYYeMQT2zEGhg3MPTQH4GKoRCreaUr9TG3uVRWjmZiVyA3gFfXTAdYWw7KMmEQM4K4hEerNNtICHN2ctaSz25kVb5qeuGbXnLWLtqdFRplicFNyrtlfGxr6myw6ypTYccm51sdUssoL7AwkZQtP6QV2BpKqhadbdIGdgZas2X2VwjOwb8F90Ij6GZ1CRM61ZWZC/UxsbhdD49wBt/wDstize0bMiG/EjPhGkBkZAg920POTEPfs3vf/lqUFPRdvpkLa4mJdH+4t/QOjmYoQPY7b+2I7fmNwcMyWC9HjOB/a56PQYzYdQYnQfkbb5s8QRaiYlYlockvpJHROseUZidAM34Mx2koMz5+8AAAAAElFTkSuQmCC" />
            </div>
            <div>Delete bounding box</div>
          </div>
          <div>
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACfklEQVR4nO2Zz2pTQRTGf4kp1r6AKNgu9AEUdOlGBG3tA+iiWkkqUtzV6NYXUB8gbyDu1Kx1a+tCENqFNtaI4kbRZZt0ZOALjCHUO/dP7wzcDw6EyZw/H3PPuWfOhQoVKuSBY8AK0AW+AnuAKUj25KMrn9O5MAAWgS8FBv4/2QEWspK4Bwxl8C2wBJwCGhSHhnzcBNblewCsZjmJoYysATUOHzXgvhPHfJqcGD1OlkTZeKBYPvvmzB3ncSrjJMZhY9hQTC080JWSzYlQcEsxvfRR6kvJJl0omFVM9pFPjF0pFVmdfDGlmGxsiTGq4aHB+MaVF5HTwE/JmZiJPHJs2d9REqkBn4A/ku0cSrkpg8hF2ehIjNaiI9KRjUuSEamoiEwDv4BvwBGgrtb8NzATE5Eb0n/irD3W2vWYiHSlf8FZO681+18URI7rlvdxQpXaUjt+IgYia9J9Dlwek2f6z94xgifyPsH19UPoRM5Kb0t3mkmyqT3nQibyVHrtA/a0J1S0oIg0gO/APjB3wL6TuoP/UGseHJFF6bxOsPeN9l4LkUhH7Xozwd6m9nZiaePzhklLJMSrrvFRMgEOH+ayELFjy1BwOwuR9QAHdMZH0W0n0vZFeeLhWEyJMVLY18urXdLJ1EViqFhSE2k5nxU2NLacTfFG9sGUfCwD7+R7qHePNxF30nhVExBTkmwDV9JOGsdnvzOqYHaA3HOIFiG78vFCQ3T7icMtv16z31cBlt9lxWQJJsZKYOW37uRLkl7un3HOToDltwcc9VVe0KAglPI7UNKnwqoMlF1+B8DdrIbn9RGyrPLby3ISk3KmpYrRP4Ty25evZpqcqFCB/PAXntuyfo0Q6X8AAAAASUVORK5CYII=" />
              <span>or</span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVklEQVR4nO2Zz2oUQRDGf9kkJnoXT0YQjDdfQzd4FU9qQjYiwZuaPI3gQdG7ZhH1CUxEUK9m1RVEFC8immQ3IwXfQjMsOD2zM9Mt80Gdpv599FRNVw00aNBgEjgKrAFd4DNwACQlyYFidBVzfiIMgIvApxIT/5d8BJaKkrgJDOXwJXAFOAnMUB5mFOMqsK3YA2C9yEkM5eQWMEX1mAJuO3m089TE6HUyEnVjQ7l88K2Z687rVMdJpGE57CinDh7oyshqIhRcU05PfIz6MrKiCwULysle+czYl1GZ3ckXs8rJcsuMUQ8PDYlvXnmInAZ+jJEvwFvgPnAJaHn6rZzIGdnsAe8dsXr74/h8Ayx6+q6FyLsxz+b0gX0tnW8q3uiIuB/aZ9J7SsREDCeAX9I9R8REDPeku0nkRFal+yh2Ihek+yJ2Im3pPo+dyIp0H8ZO5O7/UOzHgZ+xt985YCvmD+IRFfhoyvsewxVlL3VptDn7t+PTbsJnPX0HcY3/qlN6AFwGpj39Vk6kCiR5iYQ46iY+RkmAy4dTRYjY2jIUrBQhsh3ggi7xMUwcsd1r3dhM5ZQZI4NDLZDv1HQyLZEYKpfcRDrOb4UdrS0X1EHKwqxiLAOvFHvoDGNJ3k2jDUG7qaOtUnaB83k3jend7zF1MFsg9xyiZci+YjzWEt02L2779dr9bgXYfpeVkxHMjLXA2m/LqRerlcyY10/I0NpvTzONF5b03y6U9jtQ0efCuhzU3X4HwI2ijtsajupqv70iJzGuZjrqGP0K2m9fsVbz1ESDBkwOfwHrNcNUeyaTrAAAAABJRU5ErkJggg==" />
            </div>
            <div>
              <div>Previous or Next Image</div>
            </div>
          </div>
          <div>
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdUlEQVR4nO2ZS0oDQRCGv7UP3EkSQXEhLlx4kPE4Pg6gZ/EIroyCIaAXcCGIiBAEQTBxoauShn8gDEnEnjfWB8MwXd3p+qdqZtJd4DiO4zSUA6APfAJW8THR3EleEWc1OG9zjtM8kQg/8AUcAl2qpwscyQeLjcyVBgcRdXMsX0Ka/ZmJBneon458GccMTnOzKVisPy6kJMwjgj8jpWCeWnhqlYJ5avEPU2sfONe5tUJ2gVf1fQP2YiYr0J+ogTvASP3edR6pvTVCNoEn9bkE1oALXb8A2/E+VydkA3iUfQisqH0JuFb7M7AVM3FVQtaBe9lugdWMfRkYyP5Q4BLZihZyl9kUCE5PM8zYQ/9GCunP2OGYNc7yrLOrfmstElI05kLwiNDa1LJfXgCNFzKYI+ImzteF+HpkrLtQx+b1rL9EwZcPIkg/fGE3vG5O8nxck6myQtgN71E9PYn4zlNWQMUVa3uhJyVRSNMyQytLb47jOA5l8AMAv1MFHjUXQQAAAABJRU5ErkJggg==" />
              <span>and</span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADI0lEQVR4nO2ay05UQRCGv4giyJYZibhQXLo0iktDiG+Awk4NgrAzLtj4GMQQtgqR1/ASNF5gAt4St7JQFoJRxMUcU+Y/SYXgzLn0mUPI/MnZ9HRX199dXVXdNRAW54BpYBF4C2wCf/Rtqm0BmAIGOGDoAMaA50CU4qsDz4BRySgVV4GPTrnvwCNgHLgIVIBj+ipqu60d23LjPgDDZRDoBub3KHJT7Wlk3AI+OTlzQBctgq3sK038E7gLHM0hz3brHrAjmS81R6GoaPUjreT5BP1XgdcJlLsEbEj2+yLJdLudeJNgoiqw5sxmTW2N0C/i8c4UYmbzbieakTgJrO/jqdb1WzMyG+r/gAK8U3wmmplTn0wjdgLeIcRmY30aYdCdmaFQJDqci7WD3Qw1p3ifI9LnyJj5NMN99X0XKs6MOcWSeCez7RW36jER1GYkXiSQ0+NM7BoBEEfsGxnHeyJpMamxTwmQO9UVsdMEu1BETgDb0uEMOTAtJSztoAQihscab7uTGYsSYrlTWUQmAizmv7Q7UrKXZsxqg8Nek0NIikGNt+wgMzYlpDfFmCTu17xaUlQ15is5sCshnSnGeIX3C4gxwaQ4rnG/KcG+q/9JUSywnmqhHkEE7CWTlUTpRPzhrqU0pwNHJBSiNpHDuiPRAfky49ARKRtRm4jQ3pHAiNqmddhMazdDGh8ax0Ok8VkuVqFRDXGxWslw1Q2NyyGuugsBHh/yYlI6PMwjZEpC7DWlLCxJB3tNyYwBPY5t6bGs1egBfoR4oEMFy0iltVZjXHM/CSFs1NVF8pTY0qIT+Ky5R0II7HD1Dqv1tQozrtJ1JJTQYQnd0ctf0bgA/Apd6IkxJ8FfVB4rCqddXWS2iAm69GYb6W23vyASNc2xrPSkEFTcedkIbGYWwX15uvC0qNftzI5qfebv8/xhYMYVP5dbmdt1qXQcp9e2kndSBs0exYnYxVrQmy3SnBphSFXXmNC2KkwTMruK4kGnsthB5U5LitiRc7FXKBnm46+rYFlP8bRTV8QeCRknCISzWnErk1nabfcZu5zZ901tlsVan9y5k8df7UWT2woPEsoAAAAASUVORK5CYII="></img>
            </div>

            <div>Zoom</div>
          </div>
          <div>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGUlEQVR4nO2Zy2oUQRSGv8REExeK7o0rJfgCXvBGNiaDG29LNZKJSMxOY3ChQeMbKIqCulVXSi4KIuJloSa+gWbUuNCFgQgRSTK2FPwNRdszTvVMT9difiim69T5q86h65yuOgMNNNBALdAO9AOTwFdgCQhSaktaY1JrttXEA2A/8CVFw//XPgO5ap0YBIqa8B1wFNgAtJAeWrTGMWBKay8DA9W8iaImOQM0UX80AWctO3qSxES4nYwTWeOcbPnkGjMnre2UxZuIwtgwLZvyOGBSJBMTvuC4bBp3Ic2KZILOF3TIJrPlK8aiSGlmJ1e0yiZjW8UIc7hvCFztqpTwBJgDuspshzk18xyHLo2buWpllzPhsvRGS4znrbn6Suhc0filGtrlTNglvRclxh9Yc90vofNS4ztraJczwQTgPPA75kO1AvgBfAO+69nIbLSJO6+5MnPE4JF090Tk2yS/DdzV89aIzl7JH6ZglzNhULoXIvKLkh8CjpTQGZH8tA+ObJbu04j8tXL+WmCNnl9FdJ6JuykFuxIRZoAFYKX6oeHG0BDPdWkyjqGYWNBBEF8cuSX97eofjDk5D0l2QP0d6t9M0S5cCWEMDKt/Q/1OS2eLZNfVP6/+4RTtwpWwThefCfU/artF8cGSPxZnfYp2keSs9Vbfg05xr8boXLPelNF947hGUA9HRsW5o9/uGJ2cxsLvijnieOfIbov3S9flKNqVqUI9c8TxzpEWbRfDGyujNy6dnxUeS+ruiME9HcnL3avz0jG6+OpI2giSOuLjVTdwIQUeFh82VuOIKVv6ghPVODLlYYEucCEGVjO116wxHLGpYoSEPyogD2X0ZprlRFG2JHYkb/2tMK2yZUeCD5kLWrVGL/BeaxdVhXF2xK40duvEGmTUZoB9SSuN0drvamUwc7woWI6m0Ra1xpiK6O2R9OtU+53wMP32VnCO+wf9nqXfZiteSlUsY9GmPyF9S78FYJUrOaerqC/pd1lBnwgDmiDr9LsMnKp24h7VnrJKv4Vq3kRczOSVMWbrkH5ntVZfkphooAFqh7/XPNmgMpda9gAAAABJRU5ErkJggg=="></img>
            <div>Copy Previous Image</div>
          </div>
          <div>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACs0lEQVR4nO2ZPWgUURSFvywbTYKFiAqiRiu7lLYWNsZgYeNfoUZ2IxLEwt/GwkqtIlhpZWUTtEqMaKGFnYlgZZdsdIOigoIoarLrkydn4DE7uzsjOvMG5sCF3b139p2zb+7d++5AgQIF/hb9wBgwAywBK4BJ2Va09oy49CUVsQ94kwFx08VeAyNxRZwGmrrwOXAU2AqUSR9lrX0MmBWnBjAeZyeaCj4H9OAPeoDzDr+9nXIiuJ2sCF9xURwX2+XMSed28mknwrDc5sS12uJVZTDKCd9xXFyno5x1OW1y+Y5BcbWp0IJlObOoTknRK66WcwuCWp0XmHZ8CyEZwRQ7EkK7fqhCznbEqGRfD9nOf8c1PSHPusQcASaBh8CNiP+oQ8Ad+SeALT4KOaOYx8At4ANQA1bJf1b+eWAK+Aq8BTZmIeS7iLgW/OqPgM/687IYUj+3Tg3qF+CVXlvsAj4Bp7IQ8g64HbL18l9WzJI+PwyscURZ39UE62V2a9ku9SBwH3jvtN4bVBDs+ysJ1stMyHZgk16XgGu6xib4Wh2Qnjjxm7Vzw1kI+aiq5Npu+Z8C33RI26/3P4Ed8t/Vd0wq5qX8Q2kLmW9jB+S3pfSeEviHDmzu4GAAuAksyP8iyWChaFE8hCmaxrztSJn8HHVNlNPkaPiwLY4QO570HSfiCJnN0YDORAW4pzw7Y/UVl0JcWxA4fqkPuuDZzpQkoimOXYVUnccKcxpPDjrnijTRq7VH1cIYcat0EuJOGofVAxnPbAHY023SGJ79DqiCTeuIGghN05a19pSG6/2h8hs5+32Qo/I7Kq5WYAvGclJ+S06+RM7Q+vSwMS/ltwasbhc0oudzvpffhpK+I8YV6Gv5bSQYGf15YrroQbk1IavF2YmonKmqMtQzLL91cah0yokCBfg/+A0ArutLpCZBCQAAAABJRU5ErkJggg==" />{" "}
            <div>Exit to Dashboard</div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default FullscreenControlPanel;
