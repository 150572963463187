import ReactDOM from "react-dom/client";

import ErrorPage from "./pages/ErrorPage";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Navbar from "./components/Navbar";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import GlobalStyle from "./globalStyles.js";
import { theme } from "./globalStyles.js";
import { ThemeProvider } from "styled-components";
import ContextProvider from "./context/context";
import HomePage from "./pages/HomePage";

import ProtectedRoute from "./components/ProtectedRoute";
import AuthContextProvider from "./context/auth";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthContextProvider>
        <Navbar />
      </AuthContextProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/:sequence_name/:frame",
        element: (
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/:sequence_name",
        element: (
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <ContextProvider>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ContextProvider>
  </ThemeProvider>
);
