import { Selection } from "../@interfaces/interfaces";

const url = process.env.REACT_APP_API_URL;

const fetchAPI = async (
  endpoint: string,
  method: "GET" | "POST" | "DELETE",
  token: string,
  body?: any
) => {
  try {
    const options: RequestInit = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${url}${endpoint}`, options);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      if (response.status === 404) {
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    }
  } catch (error: any) {
    console.log("error occured: ", error);
    return error;
  }
};

export const deleteLock = async (token: string, sequence_name: string) =>
  fetchAPI(`lock/${sequence_name}/`, "DELETE", token);

export const deleteReject = async (token: string, sequence_name: string) =>
  fetchAPI(`sequence/deleteRejected/`, "POST", token, { sequence_name });

export const createLock = async (token: string, sequence_name: string) =>
  fetchAPI(`lock/${sequence_name}/`, "POST", token);

export const fetchSignleLock = async (token: string, sequence_name: string) =>
  fetchAPI(`lock/${sequence_name}/`, "GET", token);

export const fetchUnmarkedMarkedList = async (
  token: string,
  sequence_name: string
) => fetchAPI(`sequence/${sequence_name}/`, "GET", token);

export const fetchSequence = async (
  token: string,
  sequence_name: string,
  frame: string
) => fetchAPI(`sequence/${sequence_name}/${frame}/`, "GET", token);

export const createMaskOfEntireSequence = async (
  token: string,
  sequence_name: string
) => fetchAPI(`sequence/mask/`, "POST", token, { sequence_name });

export const sendMarkedSequence = async (
  selections: Selection[],
  sequence_name: string,
  frame_00: string,
  token: string,
  user: { user_id: number; username: string },
  images: {
    sequenceName: string;
    images: { imageName: string; image: string }[];
  }
) => {
  for (let i = 0; i < images.images.length; i++) {
    let mark = 0;
    selections.forEach((item: Selection) => {
      if (item.imageId === i) mark++;
    });
    if (!mark)
      selections.splice(i, 0, {
        imageId: i,
        selection: { selectionId: 0, edges: [] },
      });
  }

  fetchAPI(`sequence/`, "POST", token, {
    selections,
    sequence_name,
    user,
    frame_00,
    length: images.images.length,
  });
};

export const getSequenceById = async (token: string, sequenceId: number) =>
  fetchAPI(`sequence/${sequenceId}`, "GET", token);

export const getSequenceImagesByNameAndFrame = async (
  token: string,
  seqName: string,
  frame_00: string
) => fetchAPI(`sequence/${seqName}/${frame_00}`, "GET", token);

export const get_folders = async (token: string) =>
  fetchAPI(`sequence/`, "GET", token);

export const submitSequenceForReview = async (
  token: string,
  sequence_name: string
) => fetchAPI(`sequence/submit/`, "POST", token, { sequence_name });

export const returnSequence = async (token: string, sequence_name: string) =>
  fetchAPI(`sequence/return/`, "POST", token, { sequence_name });

export const checkStaff = async (token: string) =>
  fetchAPI(`user/`, "GET", token);

export const save_nosmoke = async (token: string, sequence_name: string) =>
  fetchAPI(`sequence/noSmoke/`, "POST", token, { sequence_name });

export const undo_save_nosmoke = async (token: string, sequence_name: string) =>
  fetchAPI(`sequence/noSmokeUndo/`, "POST", token, { sequence_name });
